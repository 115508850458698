<template>
  <div class="PageBox" :key="radio">
    <div class="header_bg">
      <div style="text-align: right;font-size: 16px;padding: 5px">
        <van-row>
          <van-col span="18" @click="gotoApp" style="text-align: left">
            {{$t('common5[6]')}}
          </van-col>
          <van-col span="6" @click="showLanguage=true">{{$t('login.text[0]')}}</van-col>
          <!-- <van-col span="6" @click="$router.push('/language')">{{$t('login.text[0]')}}</van-col> -->
        </van-row>
      </div>
    </div>

    <div class="topDiv">
      <div class="login_text1">{{$t('login.text1[0]')}}</div>
      <div class="login_text2">{{$t('login.text1[1]')}}</div>
    </div>

    <div class="loginpanel">
      <div style="padding-top: 20px">
        <van-field
                type="text"
                autocomplete="off"
                style="height: 0; width: 0; padding: 0; position: absolute"
        />
        <van-field
                type="password"
                autocomplete="off"
                style="height: 0; width: 0; padding: 0; position: absolute"
        />

        <div class="label mt-10">{{$t('login.text1[2]')}}</div>
        <van-field
                :formatter="formatterUsername"
                :border="false"
                class="input"
                v-model.trim="postData.username"
                :placeholder="$t('login.placeholder[0]')"
                size="large"
                clearable
                autocomplete="off"
        />
        <div class="label">{{$t('login.text1[3]')}}</div>

        <van-field
                :border="false"
                class="input"
                v-model.trim="postData.password"
                :type="showPass ? 'text' : 'password'"
                :placeholder="$t('login.placeholder[1]')"
                size="large"
                clearable
                autocomplete="off"
                :right-icon="showPass ? 'eye' : 'closed-eye'"
                @click-right-icon="showPass = !showPass"
        />

      </div>


      <div style="padding: 10px 0px;text-align: center">
        <p class="btn" @click="onSubmit()">{{ $t('login.text[2]') }}</p>

        <div class="flex_sb ml-30">

          <div>
            {{$t('login.default[0]') }} ,
            <router-link class="href" slot="a" to="/register">{{
              $t('login.default[1]')
              }}</router-link>
          </div>

          <div style="text-align: right;margin-right: 30px">
            <router-link class="href" to="/resetpwd">{{
              $t('login.default[2]')
              }}</router-link>
          </div>
        </div>
      </div>

    </div>

    <van-action-sheet v-model="showLanguage" :title="$t('login.text[0]')">
      <div class="content">
        <div @click="changeLanguage('en-US')" class="l_item" :class="radio==1?'active_l_item':''">English</div>
        <div @click="changeLanguage('vn')" class="l_item" :class="radio==2?'active_l_item':''">Tiếng Việt</div>
        <div @click="changeLanguage('zh-CN')" class="l_item" :class="radio==3?'active_l_item':''">简体中文</div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
  export default {
    name: "Login",
    components: {},
    props: ["username"],
    data() {
      return {
        showLanguage:false,
        postData: {
          username: localStorage["UserName"] || "",
          password: localStorage["PassWord"] || "",
          uniqueID: localStorage["phoneId"] || "", //手机唯一标识
        },
        keepUser: localStorage["KeepUser"] == "1" ? true : false,
        isSubmit: false,
        showPass: false,
        language1: localStorage.getItem("Language") == "cn",

        radio:''
      };
    },
    computed: {},
    watch: {},
    created() {
      this.isSubmit = false;
      console.log(this.language1);

      this.selectLan()
    },
    mounted() {
      var query= this.$route.query;
      if (query["username"]){
        this.postData.username = query["username"];
      }
    },
    activated() {},
    destroyed() {},
    methods: {
      changeLanguage(lan) {
        this.$SetLanguage(lan);
        this.$router.go(-1);
        this.selectLan();
      },
      selectLan() {
        let a = localStorage.getItem("Language");
        switch (a) {
          case "en":
            this.radio = "1";
            // console.log("11111");
            break;
          case "vn":
            this.radio = "2";
            break;
          case "cn":
            this.radio = "3";
            break;
          case "tc":
            this.radio = "4";
            break;
          case "fr":
            this.radio = "5";
            break;
        }
      },


      onSubmit() {
        if (!this.postData.username) {
          this.$Dialog.Toast(this.$t("login.placeholder[0]"));
          return;
        }
        if (!this.postData.password) {
          this.$Dialog.Toast(this.$t("login.placeholder[1]"));
          return;
        }
        this.isSubmit = true;
        this.$Model.Login(this.postData, (data) => {
          this.isSubmit = false;
          if (data.code==0){
            let idx = data.code_dec;
            let msg = this.$t("login.codes['"+idx+"']");
            this.$toast(msg);
            return;
          }
          var appData = {};
          try {
            var data = localStorage["AppData"];
            if (data) {
              appData = JSON.parse(data);
            }
          } catch (e) {
            // alert('3'+e.message)
            appData = {};
          }
          if (this.keepUser) {
            appData["UserName"] = this.postData.username;
            appData["PassWord"] = this.postData.password;
            localStorage["UserName"] = this.postData.username;
            localStorage["PassWord"] = this.postData.password;
          } else {
            appData["UserName"] = null;
            appData["PassWord"] = null;
            localStorage.removeItem("UserName");
            localStorage.removeItem("PassWord");
          }
          var data = JSON.stringify(appData);
          localStorage["AppData"] = data;
          var ret = this.$Util.writeAppStorage(data);
          this.$router.push("/");
        });
      },
      changeKeepUser() {
        var appData = {};
        try {
          var data = localStorage["AppData"];
          if (data) {
            appData = JSON.parse(data);
          }
        } catch (e) {
          // alert('3'+e.message)
          appData = {};
        }
        if (this.keepUser) {
          appData["KeepUser"] = 1;
          localStorage["KeepUser"] = 1;
        } else {
          appData["KeepUser"] = 0;
          localStorage.removeItem("KeepUser");
        }
        var data = JSON.stringify(appData);
        localStorage["AppData"] = data;
        this.$Util.writeAppStorage(data);
      },
      // 用户名格式化不能输入空格
      formatterUsername(value) {
        return value.replace(/\s*/g, "");
      },
      gotoApp(){
        window.location.href = this.InitData.setting.app_url;
      }
    },
  };
</script>
<style scoped lang="scss">
.topDiv{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 260px;
  background: url(~@/assets/img/new/login_top.png) no-repeat;
  background-size: 100%  auto;
  .login_text1{
    font-size: 28px;
    font-weight: 600;
    color: #000;
    margin-bottom: 4px;
    margin-top: 100px;
    margin-left: 32px;
  }
  .login_text2{
    font-size: 16px;
    color: #000;
    margin-left: 32px;
  }
}


  .LoginHeader {
    padding: 0 0;
  }

  .PageBox {
    color: #d7d7d7;
    padding: 0;
    background-color: #fff;
  }
  .ScrollBox {

  }

  .loginpanel {
    background: #fff;
    height:330px;
    width: 100%;
    padding-bottom: 60px;
    position: relative;
    z-index: 11;
    top: 180px;
    border-radius: 16px 16px 0 0;
  }

  .logo {
    margin: 50px auto;
    margin-left: 90px;
  }

  .loginpanel img {
    margin: 20px auto;
    text-align: center;
    width: 200px;
  }

  .header_bg {
    position: relative;
    width: 100%;
    z-index: 10;
    color: #000;
  }
  .label{
    font-size: 16px;
    color: #333;
    margin-left: 30px;
    margin-bottom: 10px;
  }
  .van-cell--borderless.input {
    width: 85%;
    background-color: #F4F7FE;
    border-radius: 22.5px;
    overflow: hidden;
    margin-bottom: 25px;
    margin-left: 30px;
    padding: 0 30px 0 13px;
    height: 45px;
  }
  .van-cell ::v-deep .van-field__body {
    height: 100%;
  }
  .van-cell ::v-deep .van-icon-closed-eye {
    font-size: 19px;
    color: #939393;
    margin-top: 5px;
  }
  .van-cell--borderless ::v-deep .van-cell__value--alone {
    height: 100%;
  }

  .van-cell--borderless ::v-deep .van-checkbox__label {
    color: #555 !important;
    font-size: 13px;
    font-family: Alibaba;
    margin-top: -1px;
  }

  .van-cell--borderless ::v-deep .van-icon-success {
    border: 1px solid #ff93a4;
    box-shadow: 0px 0px 0px 0.5px #ff93a4;
    margin-left: 1px;
  }


  .van-cell--borderless ::v-deep .van-icon {
    font-size: 25px !important;
    color: #ccc !important;
    // margin-top: 6px;
  }

  .van-cell--borderless.input ::v-deep .van-field__control {
    color: #333 !important;
    padding-left: 7px;
    height: 100%;
    font-size: 18px;
  }

  .van-cell--borderless.input ::v-deep .van-field__control::-webkit-input-placeholder {
    color: #939393;
    font-size: 19px;
    font-family: Alibaba;
  }

  .van-cell--borderless.input ::v-deep .van-field__control::-moz-placeholder {
    color: #939393;
    font-size: 19px;
    font-family: Alibaba;
  }

  .van-cell--borderless.input ::v-deep .van-field__control::-ms-input-placeholder {
    color: #939393;
    font-size: 18px;
    font-family: Alibaba;
  }

  .van-form {
    padding: 0 35px;
  }

  .van-button--danger {
    max-width: 200px;
    margin: auto;
    font-family: Alibaba;
    font-size: 20px !important;
    font-weight: bold;
    background: linear-gradient(90deg, #fff, #fff);
    color: #4e51bf;
  }

  .goRe a {
    color: #fe889d;
  }

  .van-cell--borderless ::v-deep .van-icon-manager,
  .van-cell--borderless ::v-deep .van-icon-lock {
    color: #ccc !important;
  }

  .van-cell--borderless ::v-deep .van-icon-manager::before,
  .van-cell--borderless ::v-deep .van-icon-lock::before {
    font-size: 27px;
    margin-top: 4px;
  }

  .van-cell--large {
    padding-top: 17px;
    padding-bottom: 8px;
  }

  .Site .van-nav-bar ::v-deep .van-icon {
    color: #333;
  }

  .van-nav-bar__text1 ::v-deep .van-nav-bar__text {
    letter-spacing: 0.09rem;
    color: #000000;
  }

  .PageBox ::v-deep .letter_s {
    letter-spacing: 0.08rem;
  }
  .goRe {
    margin-top: 20px;
    font-family: Alibaba;
    font-size: 15px;
    text-align: left;
    color: #4e51bf;
  }

  .href {
    color: #5FD6E8;
  }

  .PageBox a:link {
    color: #5FD6E8;
  }

  .btn {
    width: 85%;
    border-radius: 24px;
    line-height: 48px;
    height: 48px;
    background: linear-gradient(135deg, #8AE8F3,#3FC4D5);
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 15px 30px 30px;
  }
.content{
  padding: 60px 0;
}
  .l_item{
    font-size: 18px;
    color: #333;
    padding: 10px ;
    text-align: center;
  }
  .active_l_item{
    color: #5FD6E8;
  }
</style>
